import { defineAsyncComponent } from 'vue';
import { onLoadScrollContent } from './site/Post';

import app from '@perevorot/shop/dist/app';
import store from '@perevorot/shop/dist/vuex';

import SwiperCore, { Navigation, Mousewheel, Controller, Autoplay, Thumbs, Pagination as SwiperPagination } from 'swiper/core';

SwiperCore.use([Navigation, Mousewheel, Controller, Autoplay, Thumbs, SwiperPagination]);

import SecureLS from 'secure-ls';
import Cookies from 'js-cookie';
import route from '@/global/route';
import messages from '@/global/messages';

import Subscribe from '@/forward/Subscribe';
import PromoProducts from '@/forward/PromoProducts';
import BlockProducts from '@/forward/BlockProducts';
import UserId from '@/forward/UserId';

app.component('Subscribe', Subscribe);
app.component('PromoProducts', PromoProducts);
app.component('blockProducts', BlockProducts);
app.component('UserId', UserId);

const Promo = defineAsyncComponent(() => import(/* webpackChunkName: "promo" */ '@/forward/Promo'));
app.component('Promo', Promo);

const PartnerForm = defineAsyncComponent(() => import(/* webpackChunkName: "partner" */ '@/forward/PartnerForm'));
app.component('PartnerForm', PartnerForm);

const Helper = defineAsyncComponent(() => import(/* webpackChunkName: "helper" */ '@/forward/Helper'));
app.component('Helper', Helper);

const GoogleMap = defineAsyncComponent(() => import(/* webpackChunkName: "googlemap" */ '@/forward/GoogleMap'));
app.component('GoogleMap', GoogleMap);

const Feedback = defineAsyncComponent(() => import(/* webpackChunkName: "feedback" */ '@/forward/Feedback'));
app.component('Feedback', Feedback);

const CartTotal = defineAsyncComponent(() => import(/* webpackChunkName: "cart" */ '@/forward/CartTotal'));
const CartInline = defineAsyncComponent(() => import(/* webpackChunkName: "cart" */ '@/forward/CartInline'));

app.component('CartTotal', CartTotal);
app.component('CartInline', CartInline);

import Forget from '@perevorot/shop/dist/js/components/auth/Forget';
import Login from '@perevorot/shop/dist/js/components/auth/Login';
import Registration from '@perevorot/shop/dist/js/components/auth/Registration';
import Reset from '@perevorot/shop/dist/js/components/auth/Reset';
import User from '@perevorot/shop/dist/js/components/auth/User';
import Cart from '@perevorot/shop/dist/js/components/shop/cart/Cart';
import CartTrigger from '@perevorot/shop/dist/js/components/shop/cart/CartTrigger';
import CartAdd from '@perevorot/shop/dist/js/components/shop/cart/CartAdd';
import CartItem from '@perevorot/shop/dist/js/components/shop/cart/CartItem';
import Compare from '@perevorot/shop/dist/js/components/shop/compare/Compare';
import CompareAdd from '@perevorot/shop/dist/js/components/shop/compare/CompareAdd';
import Viewed from '@perevorot/shop/dist/js/components/shop/viewed/Viewed';
import ViewedDelete from '@perevorot/shop/dist/js/components/shop/viewed/ViewedDelete';
import Wishlist from '@perevorot/shop/dist/js/components/shop/wishlist/Wishlist';
import WishlistAdd from '@perevorot/shop/dist/js/components/shop/wishlist/WishlistAdd';
import WishlistDelete from '@perevorot/shop/dist/js/components/shop/wishlist/WishlistDelete';
import Search from '@perevorot/shop/dist/js/components/shop/Search';
import Cross from '@perevorot/shop/dist/js/components/shop/Cross';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

app.component('Forget', Forget);
app.component('Login', Login);
app.component('Registration', Registration);
app.component('Reset', Reset);
app.component('User', User);
app.component('Cart', Cart);
app.component('CartTrigger', CartTrigger);
app.component('CartAdd', CartAdd);
app.component('CartItem', CartItem);
app.component('Compare', Compare);
app.component('CompareAdd', CompareAdd);
app.component('Viewed', Viewed);
app.component('ViewedDelete', ViewedDelete);
app.component('Wishlist', Wishlist);
app.component('WishlistAdd', WishlistAdd);
app.component('WishlistDelete', WishlistDelete);
app.component('Search', Search);
app.component('Cross', Cross);

const FilterSearch = defineAsyncComponent(() => import(/* webpackChunkName: "category" */ '@perevorot/shop/dist/js/components/shop/filters/FilterSearch'));
const Price = defineAsyncComponent(() => import(/* webpackChunkName: "category" */ '@perevorot/shop/dist/js/components/shop/filters/Price'));
const Pagination = defineAsyncComponent(() => import(/* webpackChunkName: "category" */ '@perevorot/shop/dist/js/components/shop/Pagination'));
const FilterToggle = defineAsyncComponent(() => import(/* webpackChunkName: "category" */ '@perevorot/shop/dist/js/components/shop/FilterToggle'));
const Catalog = defineAsyncComponent(() => import(/* webpackChunkName: "category" */ '@perevorot/shop/dist/js/components/shop/Catalog'));

app.component('FilterSearch', FilterSearch);
app.component('Price', Price);
app.component('Pagination', Pagination);
app.component('FilterToggle', FilterToggle);
app.component('Catalog', Catalog);

//const Cross = defineAsyncComponent(() => import(/* webpackChunkName: "product" */ '@perevorot/shop/dist/js/components/shop/Cross'));
const OrderOneClick = defineAsyncComponent(() => import(/* webpackChunkName: "product" */ '@perevorot/shop/dist/js/components/shop/order/OneClick'));
const Gallery = defineAsyncComponent(() => import(/* webpackChunkName: "product" */ '@perevorot/shop/dist/js/components/shop/Gallery'));
const Review = defineAsyncComponent(() => import(/* webpackChunkName: "product" */ '@perevorot/shop/dist/js/components/shop/reviews/Review'));
const Bundle = defineAsyncComponent(() => import(/* webpackChunkName: "product" */ '@perevorot/shop/dist/js/components/shop/bundle/Bundle'));
const BundleAdd = defineAsyncComponent(() => import(/* webpackChunkName: "product" */ '@perevorot/shop/dist/js/components/shop/bundle/BundleAdd'));
const CodeCopy = defineAsyncComponent(() => import(/* webpackChunkName: "product" */ '@perevorot/shop/dist/js/components/shop/CodeCopy'));
const Viewer = defineAsyncComponent(() => import(/* webpackChunkName: "product" */ '@perevorot/shop/dist/js/components/shop/viewed/Viewer'));

//app.component('Cross', Cross);
app.component('OrderOneClick', OrderOneClick);
app.component('Gallery', Gallery);
app.component('Review', Review);
app.component('Bundle', Bundle);
app.component('BundleAdd', BundleAdd);
app.component('CodeCopy', CodeCopy);
app.component('Viewer', Viewer);

const Comparsion = defineAsyncComponent(() => import(/* webpackChunkName: "comparsion" */ '@perevorot/shop/dist/js/components/shop/compare/Comparsion'));
const CompareDelete = defineAsyncComponent(() => import(/* webpackChunkName: "comparsion" */ '@perevorot/shop/dist/js/components/shop/compare/CompareDelete'));

app.component('CompareDelete', CompareDelete);
app.component('Comparsion', Comparsion);

const Appointment = defineAsyncComponent(() => import(/* webpackChunkName: "appointment" */ '@/forward/Appointment.vue'));
app.component('Appointment', Appointment);

const Account = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@perevorot/shop/dist/js/components/account/Account'));
const AccountOrders = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@perevorot/shop/dist/js/components/account/Orders'));
const AccountUser = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@perevorot/shop/dist/js/components/account/User'));
const AccountViewed = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@perevorot/shop/dist/js/components/account/Viewed'));
const AccountWish = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@perevorot/shop/dist/js/components/account/Wish'));
const AccountForwardDashboard = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardDashboard.vue'));
const AccountForwardDiscounts = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardDiscounts.vue'));
const AccountForwardProposal = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardProposal.vue'));
const AccountForwardCart = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardCart.vue'));
const AccountForwardSales = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardSales.vue'));
const AccountForwardNews = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardNews.vue'));
const AccountForwardPromo = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardPromo.vue'));
const AccountForwardFinance = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardFinance.vue'));
const AccountForwardCatalogues = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardCatalogues.vue'));
const AccountForwardFeedback = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardFeedback.vue'));
const AccountForwardUser = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardUser.vue'));
const AccountForwardOrders = defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '@/forward/account/AccountForwardOrders.vue'));

app.component('Account', Account);
app.component('AccountOrders', AccountOrders);
app.component('AccountUser', AccountUser);
app.component('AccountViewed', AccountViewed);
app.component('AccountWish', AccountWish);
app.component('AccountForwardDashboard', AccountForwardDashboard);
app.component('AccountForwardDiscounts', AccountForwardDiscounts);
app.component('AccountForwardProposal', AccountForwardProposal);
app.component('AccountForwardCart', AccountForwardCart);
app.component('AccountForwardSales', AccountForwardSales);
app.component('AccountForwardNews', AccountForwardNews);
app.component('AccountForwardPromo', AccountForwardPromo);
app.component('AccountForwardFinance', AccountForwardFinance);
app.component('AccountForwardCatalogues', AccountForwardCatalogues);
app.component('AccountForwardFeedback', AccountForwardFeedback);
app.component('AccountForwardUser', AccountForwardUser);
app.component('AccountForwardOrders', AccountForwardOrders);

window.$ziggy = route;
window.__ = messages;

app.config.globalProperties.$ziggy = route;
app.config.globalProperties.__ = messages;

// try {
//     document.getElementById(window.innerWidth >= 1024 ? 'header-touch' : 'header').remove();
// } catch {}

app.use(store);

app.mount('#app');
window.app = app;

window.viewedSwiperOptions = {
    watchOverflow: true,
    observer: true,
    observeParents: true,
    freeMode: true,
    mousewheel: {
        forceToAxis: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        0: {
            slidesPerView: 'auto',
            spaceBetween: 0,
            effect: 'coverflow',
            navigation: false,

            coverflowEffect: {
                rotate: 0,
                stretch: 20,
                depth: 120,
                modifier: 3,
                slideShadows: false,
            },
        },
        769: {
            slidesPerView: 3,
            spaceBetween: 24,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 16,
        },
        1200: {
            slidesPerView: 5,
            spaceBetween: 16,
        },
        1366: {
            slidesPerView: 5,
            spaceBetween: 24,
        },
    },
};

window.crossSaleSwiperOptions = window.viewedSwiperOptions;

const initFaq = () => {
    const faq = document.getElementById('faq');

    if (faq) {
        faq.querySelectorAll('.faq-category-title').forEach((element) => {
            element.addEventListener('click', () => {
                element.closest('.faq-category').classList.toggle('open');
            });
        });

        faq.querySelectorAll('.faq-item-title').forEach((element) => {
            element.addEventListener('click', () => {
                element.closest('.faq-item').classList.toggle('open');
            });
        });

        if (faq.querySelectorAll('.faq-category-title').length) {
            faq.querySelectorAll('.faq-category-title')[0].click();
        }
    }
}

window.addEventListener('DOMContentLoaded', () => {
    let catalogButton = document.getElementById('catalog-button');
    let catalogOverlay = document.getElementById('catalog-overlay');

    catalogButton &&
        catalogButton.addEventListener('click', () => {
            catalogButton.closest('.catalog').classList.toggle('open');
        });

    catalogOverlay &&
        catalogOverlay.addEventListener('click', () => {
            catalogButton.closest('.catalog').classList.remove('open');
        });

    const blockHomeSlider = document.querySelector('.block-home-slider');
    if (blockHomeSlider) {
        const homeSlider = document.getElementById('home-slider');
        const bannerCategories = blockHomeSlider.querySelector('.banner-categories');
        const categoriesListToggler = blockHomeSlider.querySelector('.categories-list-toggler');
        // const homeSliderText = document.getElementById('home-slider-text');

        // if (homeSlider) {
        new SwiperCore(homeSlider, {
            loop: true,
            autoplay: {
                delay: 5000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
            },
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        // }

        // if (homeSlider && homeSliderText) {
        //     const homeBannerSwiper = new SwiperCore(homeSlider, {
        //         loop: false,
        //         autoplay: {
        //             delay: 5000,
        //             pauseOnMouseEnter: true,
        //             disableOnInteraction: false,
        //         },
        //         slidesPerView: 1,
        //         navigation: {
        //             nextEl: homeSliderText.querySelector('.swiper-button-next'),
        //             prevEl: homeSliderText.querySelector('.swiper-button-prev'),
        //         },
        //     });

        //     const homeBannerTextSwiper = new SwiperCore(homeSliderText, {
        //         loop: false,
        //         slidesPerView: 1,
        //         controller: {
        //             control: homeBannerSwiper,
        //         },
        //     });

        //     homeBannerSwiper.controller.control = homeBannerTextSwiper;
        // }

        categoriesListToggler.addEventListener('click', () => bannerCategories.classList.toggle('is-expanded'));
    }

    const businessBannersBlock = document.querySelector('.block-products-for-your-business');
    if (businessBannersBlock) {
        const bannersSlider = businessBannersBlock.querySelector('.shop-categories-banners-slider');
        const bannersSliderPrev = businessBannersBlock.querySelector('.swiper-button-prev');
        const bannersSliderNext = businessBannersBlock.querySelector('.swiper-button-next');

        const fourCardsSwiper = new SwiperCore(bannersSlider, {
            spaceBetween: 20,

            navigation: {
                prevEl: bannersSliderPrev,
                nextEl: bannersSliderNext,
            },
            breakpoints: {
                0: {
                    slidesPerView: 'auto',
                    spaceBetween: 16,
                    effect: 'coverflow',
                    navigation: false,

                    coverflowEffect: {
                        rotate: 0,
                        stretch: 20,
                        depth: 120,
                        modifier: 3,
                        slideShadows: false,
                    },
                },
                769: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
            },
        });
    }

    const threeCardsBlock = document.querySelectorAll('.three-items-slider');
    if (threeCardsBlock) {
        let threeCardsSwiper = new SwiperCore(threeCardsBlock, {
            loop: false,
            slidesPerView: 3,
            spaceBetween: 20,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }

    // let latestNewsSwiper = new SwiperCore('.latest-news-slider', {
    //     loop: false,
    //     slidesPerView: 3,
    //     spaceBetween: 20,
    //     observer: true,
    //     observeParents: true,
    //     navigation: {
    //         nextEl: '.swiper-button-next',
    //         prevEl: '.swiper-button-prev',
    //     }
    // });

    ////////////////////////////////////////

    let latestNewsSwiperOpts = {
        loop: false,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
        },
        breakpoints: {
            320: {
                slidesPerView: 'auto',
                freeMode: true,
                spaceBetween: 0,
            },
            769: {
                spaceBetween: 20,
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
    };

    let latestNewsSwiper;

    if (document.querySelector('.latest-news-slider')) {
        latestNewsSwiper = new SwiperCore('.latest-news-slider', latestNewsSwiperOpts);
        // let latestNewsSwiperInit = true;

        // function latestNewsSwiperMode() {
        //   let mobileViewport = window.matchMedia(
        //     "(min-width: 0px) and (max-width: 768px)"
        //   );

        //   if (mobileViewport.matches) {
        //     if (latestNewsSwiperInit) {
        //       latestNewsSwiperInit = false;

        //       latestNewsSwiper.forEach((swiper) => {
        //         swiper.destroy(true, true);
        //       });
        //     }
        //   } else {
        //     latestNewsSwiperInit = true;
        //     latestNewsSwiper = new SwiperCore(
        //       ".latest-news-slider",
        //       latestNewsSwiperOpts
        //     );
        //   }
        // }
        // window.addEventListener("load", latestNewsSwiperMode);
        // window.addEventListener("resize", latestNewsSwiperMode);
    }

    const homepageArticlesTab = document.getElementById('homepage-articles-tab');

    if (homepageArticlesTab) {
        const homepageArticlesTabTitles = homepageArticlesTab.querySelectorAll('.block-title span');
        const homepageArticlesTabLinks = homepageArticlesTab.querySelectorAll('.block-head-link');
        const homepageArticlesTabLinksMobile = homepageArticlesTab.querySelectorAll('.block-footer-link-mobile');
        const homepageArticlesTabSliders = homepageArticlesTab.querySelectorAll('.swiper-container');

        if (homepageArticlesTabTitles.length > 1) {
            homepageArticlesTabTitles.forEach((element, index) => {
                element.addEventListener('click', () => {
                    console.log(index);

                    homepageArticlesTabTitles.forEach((title, i) => {
                        title.classList.remove('is-active');

                        if (i == index) {
                            title.classList.add('is-active');
                        }
                    });

                    homepageArticlesTabSliders.forEach((slider, i) => {
                        slider.classList.add('is-hidden');

                        if (i == index) {
                            slider.classList.remove('is-hidden');
                        }
                    });

                    homepageArticlesTabLinks.forEach((link, i) => {
                        link.classList.add('is-hidden');

                        if (i == index) {
                            link.classList.remove('is-hidden');
                        }
                    });

                    homepageArticlesTabLinksMobile.forEach((link, i) => {
                        link.classList.add('is-hidden');

                        if (i == index) {
                            link.classList.remove('is-hidden');
                        }
                    });

                    if (latestNewsSwiper) {
                        latestNewsSwiper.forEach((swiper) => {
                            swiper.update();
                        });
                    }
                });
            });
        }
    }

    const articleSwiper = new SwiperCore('.article-slider', {
        loop: false,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    const aboutSwiper = new SwiperCore('.about-group-slider', {
        loop: false,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    initFaq();

    let switcher = document.getElementById('product-grid-switcher');

    if (switcher) {
        let isHorizontal = Cookies.get('isHorizontal') === 'true';

        switcher.addEventListener('click', () => {
            isHorizontal = !isHorizontal;

            Cookies.set('isHorizontal', isHorizontal, {
                expires: 365,
                path: '/',
            });

            switcher.classList.toggle('is-horizontal');
            document.getElementById('products').classList.toggle('is-horizontal');
        });
    }

    const productTabs = document.getElementById('product-tabs');

    if (productTabs) {
        const productTabsLi = productTabs.querySelectorAll('ul li');
        const productTabsContent = productTabs.querySelectorAll('.tab-content .tab');

        productTabsLi.forEach((li, index) => {
            li.addEventListener('click', () => {
                productTabsLi.forEach((el) => {
                    el.classList.remove('is-active');
                });

                productTabsContent.forEach((el) => {
                    el.classList.remove('is-active');
                });

                productTabsContent[index].classList.add('is-active');
                li.classList.add('is-active');
            });
        });
    }

    const seoText = document.getElementById('seo-text');

    if (seoText) {
        const seoTextOpen = document.getElementById('seo-open');
        const seoTextHide = document.getElementById('seo-hide');

        seoTextOpen.addEventListener('click', () => {
            seoText.classList.add('is-opened');
            seoTextOpen.classList.add('is-hidden');
            seoTextHide.classList.remove('is-hidden');
        });

        seoTextHide.addEventListener('click', () => {
            seoText.classList.remove('is-opened');
            seoTextOpen.classList.remove('is-hidden');
            seoTextHide.classList.add('is-hidden');
        });
    }

    const brandDescription = document.getElementById('brand-description');

    if (brandDescription) {
        const brandDescriptionOpen = document.getElementById('brand-description-open');
        const brandDescriptionHide = document.getElementById('brand-description-hide');

        brandDescriptionOpen.addEventListener('click', () => {
            brandDescription.classList.add('open');
            brandDescriptionOpen.classList.add('is-hidden');
            brandDescriptionHide.classList.remove('is-hidden');
        });

        brandDescriptionHide.addEventListener('click', () => {
            brandDescription.classList.remove('open');
            brandDescriptionOpen.classList.remove('is-hidden');
            brandDescriptionHide.classList.add('is-hidden');

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });
    }

    const brandTabs = document.getElementById('brand-tabs');

    if (brandTabs) {
        const brandTabsLi = brandTabs.querySelectorAll('ul li');
        const brandTabsContent = brandTabs.querySelectorAll('.brand-tabs-content .brand-tab');

        if (brandTabsLi.length > 1) {
            brandTabsLi.forEach((li, index) => {
                li.addEventListener('click', () => {
                    brandTabsLi.forEach((el) => {
                        el.classList.remove('is-active');
                    });

                    brandTabsContent.forEach((el) => {
                        el.classList.remove('open');
                    });

                    brandTabsContent[index].classList.add('open');
                    li.classList.add('is-active');
                });
            });
        }
    }

    const promoDescription = document.getElementById('promo-info-text');

    if (promoDescription) {
        const promoDescriptionOpen = document.getElementById('promo-info-open');
        const promoDescriptionHide = document.getElementById('promo-info-hide');

        promoDescriptionOpen.addEventListener('click', () => {
            promoDescription.classList.add('open');
            promoDescriptionOpen.classList.add('is-hidden');
            promoDescriptionHide.classList.remove('is-hidden');
        });

        promoDescriptionHide.addEventListener('click', () => {
            promoDescription.classList.remove('open');
            promoDescriptionOpen.classList.remove('is-hidden');
            promoDescriptionHide.classList.add('is-hidden');

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });
    }

    const clientMore = document.getElementById('clients-more');

    if (clientMore) {
        const clientMoreItems = clientMore.closest('.clients-logos').querySelectorAll('.logo-item.is-hidden');

        clientMore.addEventListener('click', () => {
            clientMore.parentElement.classList.add('is-hidden');

            clientMoreItems.forEach((element) => {
                element.classList.remove('is-hidden');
            });
        });
    }

    let headerTouch = document.getElementById('header-touch');

    if (headerTouch) {
        let isSlided = false;
        let mobileMenu = headerTouch.querySelector('.mobile-menu');
        let mobileMenuClose = headerTouch.querySelector('.mobile-menu-close');
        let mobileMenuCatalog = headerTouch.querySelector('.mobile-menu-catalog');

        headerTouch.querySelector('.burger').addEventListener('click', () => {
            mobileMenu.classList.add('is-open');
        });

        mobileMenuClose.addEventListener('click', () => {
            mobileMenu.classList.remove('is-open');
        });

        headerTouch.querySelector('.mobile-menu-overlay').addEventListener('click', () => {
            mobileMenu.classList.remove('is-open');
        });

        headerTouch.querySelector('.catalog-button-main').addEventListener('click', () => {
            mobileMenuCatalog.classList.add('open');
        });

        headerTouch.querySelector('.catalog-return-button').addEventListener('click', () => {
            mobileMenuCatalog.classList.remove('open');
        });

        const catalogDropdownContainer = headerTouch.querySelector('.catalog-dropdown');

        headerTouch.querySelectorAll('.catalog-dropdown-category').forEach((dropdown) => {
            let hasList = dropdown.querySelector('.catalog-dropdown-category-list');

            if (hasList) {
                dropdown.addEventListener('click', (e) => {
                    if (!e.target.classList.contains('catalog-dropdown-category-link') && !isSlided) {
                        e.preventDefault();

                        hasList.classList.add('is-open');
                        catalogDropdownContainer.scrollTop = 0;
                        isSlided = true;
                    }
                });
            }
        });

        headerTouch.querySelectorAll('.catalog-dropdown-category-list .all-categories-return').forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();

                item.closest('.catalog-dropdown-category-list').classList.remove('is-open');
                isSlided = false;
            });
        });

        headerTouch.querySelectorAll('.catalog-dropdown-category-list .catalog-button').forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();

                item.closest('.catalog-dropdown-category-list').classList.remove('is-open');
                isSlided = false;
            });
        });

        const mobileMenuHelp = headerTouch.querySelector('.mobile-menu-help');

        headerTouch.querySelector('.help-button').addEventListener('click', () => {
            mobileMenuHelp.classList.add('is-open');
        });

        headerTouch.querySelector('.help-group-return').addEventListener('click', () => {
            mobileMenuHelp.classList.remove('is-open');
        });
    }

    const textCheckList = document.getElementById('text-check-list');

    if (textCheckList) {
        const textCheckListOpen = document.querySelector('.list-content-toggler-mobile');

        textCheckListOpen.addEventListener('click', (event) => {
            textCheckList.classList.toggle('is-opened');
            event.target.classList.toggle('is-opened');
        });
    }

    const infoPage = document.querySelector('.block-info');

    if (infoPage) {
        const mobileInfoSidebarToggler = infoPage.querySelector('.info-sidebar-toggler-mobile');
        const infoSidebarContent = infoPage.querySelector('.info-sidebar-inner');

        if (mobileInfoSidebarToggler) {
            mobileInfoSidebarToggler.addEventListener('click', () => {
                infoSidebarContent.classList.toggle('is-opened');
            });
        }
    }

    const accountPage = document.querySelector('.block-account');

    if (accountPage) {
        const mobileAccountSidebarToggler = accountPage.querySelector('.account-sidebar-toggler-mobile');
        // const accountSidebarContent = accountPage.querySelector('.account .column.is-3');

        mobileAccountSidebarToggler.addEventListener('click', () => {
            let accountSidebarContent = accountPage.querySelector('.account .column.is-3');
            accountSidebarContent.classList.toggle('is-opened');
        });
    }

    const productInfoToggle = document.querySelector('.product-bottom-info');

    if (productInfoToggle) {
        productInfoToggle.addEventListener('click', () => {
            productInfoToggle.classList.toggle('is-open');
        });
    }

    const regionItemTitle = document.querySelectorAll('.region-item-title');

    if (regionItemTitle.length) {
        regionItemTitle.forEach((el) => {
            el.addEventListener('click', () => {
                el.closest('.region-item').classList.toggle('open');
            });
        });
    }

    const blockContacts = document.querySelector('.contacts-departments');
    if (blockContacts) {
        const contactsDepartmentSlider = new SwiperCore('.contacts-group-places', {
            loop: true,
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
            if (this.getAttribute('href') !== '#') {
                const selector = document.querySelector(this.getAttribute('href'));

                if (selector) {
                    e.preventDefault();

                    selector.scrollIntoView({
                        behavior: 'smooth',
                    });
                }
            }
        });
    });

    const longreadSlider = document.querySelector('.longread-slider');

    if (longreadSlider) {
        new SwiperCore(longreadSlider, {
            loop: true,
            slidesPerView: 1,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoplay: {
                delay: 5000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
            },
        });
    }

    const articleProductsSlider = document.querySelector('.article-products-swiper');

    if (articleProductsSlider) {
        new SwiperCore(articleProductsSlider, window.viewedSwiperOptions);
    }
});

$http.get('/images/icons.svg?v=2').then((response) => {
    document.getElementById('icons-container').innerHTML = response.data;
});

const iconsAccount = document.getElementById('icons-account-container');

if (iconsAccount) {
    $http.get('/images/icons-account.svg?v=1').then((response) => {
        iconsAccount.innerHTML = response.data;
    });
}

const blockProduct = document.querySelector('.shop-product');

if (blockProduct) {
    const prevThumbBtn = document.getElementById('thumb-prev');
    const nextThumbBtn = document.getElementById('thumb-next');
    const prevPhotoBtn = document.getElementById('product-gallery-prev');
    const nextPhotoBtn = document.getElementById('product-gallery-next');

    const thumbsSlider = document.getElementById('product-gallery-thumbs');
    const gallerySlider = document.getElementById('product-gallery');

    if (prevThumbBtn) {
        let galleryThumbs = new SwiperCore(thumbsSlider, {
            direction: 'vertical',
            slideToClickedSlide: true,
            slidesPerView: 4,
            navigation: {
                prevEl: prevThumbBtn,
                nextEl: nextThumbBtn,
            },
        });

        let gallery = new SwiperCore(gallerySlider, {
            navigation: {
                prevEl: prevPhotoBtn,
                nextEl: nextPhotoBtn,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar',
            },
            thumbs: {
                swiper: galleryThumbs,
            },
        });

        gallery.on('slideChange', () => galleryThumbs.slideTo(gallery.activeIndex));
        galleryThumbs.on('slideChange', () => gallery.slideTo(galleryThumbs.activeIndex));
    }

    const productInfo = blockProduct.querySelector('.product-info');
    const cardOfferFixedMobile = blockProduct.querySelector('.card-offer-fixed-mobile');

    // setTimeout(()=> {
    // console.log(productInfo.offsetHeight);
    // let cardOfferFixedMobileHeight = cardOfferFixedMobile.offsetHeight;
    let productInfoVisualGap = window.scrollY + productInfo.getBoundingClientRect().top + productInfo.offsetHeight;
    let prevScrollpos = window.scrollY;

    window.addEventListener('scroll', () => {

        let currentScrollPos = window.scrollY;

        if (window.scrollY > productInfoVisualGap) {
            cardOfferFixedMobile.classList.add('is-visible');

            // if (prevScrollpos > currentScrollPos) {
            //     cardOfferFixedMobile.classList.add('is-visible');
            //     cardOfferFixedMobile.style.bottom = '0';
            // } else {
            //     cardOfferFixedMobile.classList.remove('is-visible');
            //     cardOfferFixedMobile.style.bottom = `-${cardOfferFixedMobileHeight}px`;
            // }
        } else {
            cardOfferFixedMobile.classList.remove('is-visible');
            // cardOfferFixedMobile.style.bottom = '0';
        }

        prevScrollpos = currentScrollPos;
    });
    // }, 1000);


}

const postContent = document.querySelector('section.block.block-article');

if (postContent) {
    window.addEventListener('scroll', () => {
        onLoadScrollContent();
    });
}

const sendViewItemListEvent = (products, name) => {
    let items = [];

    products.forEach((product, index) => {
        if (!product.classList.contains('is-tracked')) {
            items.push({
                item_name: product.querySelector('[shop-product-name]').innerText,
                item_id: product.dataset.code,
                price: product.querySelector('[shop-product-price]') ? product.querySelector('[shop-product-price]').childNodes[0].nodeValue.replace(/ /g, '') : null,
                item_brand: product.dataset.brand,
                item_category: product.dataset.category,
                item_list_name: name,
                index: index + 1,
                quantity: 1,
            });

            product.classList.add('is-tracked');
        }
    });

    let object = {
        ecommerce: {
            items: items,
        },
        event: 'view_item_list',
    };

    if ($env.debug.ecommerce) {
        console.log(object);
    }

    if (!$env.debug.ecommerce && window.dataLayer) {
        window.dataLayer.push({
            ecommerce: null,
        });

        window.dataLayer.push(object);
    }
};

const subcategory = document.querySelector('.shop-subcategory');

if (subcategory) {
    let listName = '';

    if (window.location.href.includes('/search')) {
        listName = 'Search';
    } else if (window.location.href.includes('/brand')) {
        listName = 'Brand';
    } else {
        listName = 'Category';
    }

    sendViewItemListEvent(subcategory.querySelectorAll('.card.product'), listName);
}

window.loadProductsCallback = (container, name, extra) => {
    const productsContainer = container ? container : document.querySelector('.shop-subcategory');

    if (productsContainer) {
        sendViewItemListEvent(productsContainer.querySelectorAll('.card.product'), name);
    }

    const seoText = document.querySelector('.block.block-text-accordeon');
    const faqText = document.querySelector('.block.block-info.mt-6');

    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    const page = pathSegments[pathSegments.length - 1];
    const pageNumber = page.startsWith('page') ? parseInt(page.slice(4)) : 1;

    if (seoText) {
        seoText.style.display = pageNumber > 1 ? 'none' : 'block';
    }

    if (faqText) {
        faqText.style.display = pageNumber > 1 ? 'none' : 'block';
    }

    if (extra) {
        const linkingBlock = document.querySelector('.linking-block');

        if (linkingBlock) {
            linkingBlock.innerHTML = extra.linking_block;
        }
    }
};

const headerGroup = document.querySelector('.header-group');
if (headerGroup) {
    const salesHeader = headerGroup.querySelector('.sales-header');
    const headerDesktop = document.getElementById('header');
    const headerTopDesktop = headerDesktop.querySelector('.header-top');
    const headerMobile = document.getElementById('header-touch');
    const headerTopMobile = headerMobile.querySelector('.header-top');
    const salesHeaderHeight = salesHeader.offsetHeight || 0;
    let headerTopDesktopHeight;
    let headerTopMobileHeight;
    let headerOffset;

    // console.log(salesHeaderHeight);

    function headerHeightFunc() {
        if (window.innerWidth > 768) {
            headerTopDesktopHeight = headerTopDesktop.offsetHeight;
            headerOffset = salesHeaderHeight + headerTopDesktopHeight;
            // console.log('headerTopDesktopHeight ' + headerTopDesktopHeight);
            // console.log('headerDesktopOffset ' + headerOffset);
        } else {
            // headerTopMobileHeight = headerTopMobile.offsetHeight;
            // headerOffset = headerTopMobileHeight;
            headerOffset = salesHeaderHeight;
            // console.log('headerTopMobileHeight ' + headerTopMobileHeight);
            // console.log('headerMobileOffset ' + headerOffset);
        }
        return headerOffset;
    }

    window.onscroll = function () {
        headerOffsetFunc(headerHeightFunc());
    };

    function headerOffsetFunc(headerOffset) {
        // console.log(headerOffset, window.pageYOffset);
        if (window.pageYOffset > headerOffset) {
            headerGroup.style.top = `-${headerOffset}px`;
            // console.log('window.pageYOffset > headerOffset');
        } else {
            headerGroup.style.top = `-${window.pageYOffset}px`;
            // console.log('window.pageYOffset <= headerOffset');
        }
    }
}

const scrollTopBtn = document.querySelector('.scroll-top-btn');

scrollTopBtn.addEventListener('click', () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
});

const blockLogos = document.querySelector('.block-our-clients');
if (blockLogos) {
    const swiper = blockLogos.querySelector('.swiper-container');
    new SwiperCore(swiper, {
        slidesPerView: 'auto',
        freeMode: true,
        // centerMode: true,
        // initialSlide: 1,
        pagination: {
            el: '.swiper-pagination',
        },
    });
}

window.catalogFilterCallback = (extra) => {
    [('https://forward-ua.com/brand/forest', 'https://forward-ua.com/ru/brand/forest')].forEach((path) => {
        document.querySelectorAll('[href="' + path + '"]').forEach((el) => {
            el.rel = 'nofollow';
        });
    });

    if (extra) {
        const faq = document.querySelector('.block.block-info.mt-6');

        if (faq) {
            faq.remove();

            if (extra.faq) {
                const main = document.querySelector('main');

                const faqWrapper = document.createElement('div');
                faqWrapper.innerHTML = extra.faq;

                const faq = faqWrapper.querySelector('.block.block-info.mt-6');

                main.appendChild(faq);

                initFaq();
            }
        }
    }

    let ls = new SecureLS({
        isCompression: false,
    });

    let filter = ls.get('filter');

    if (!filter) {
        filter = {
            brand: true,
            category: true,
            price: true,
        };

        ls.set('filter', filter);
    }

    const catalogFilter = document.getElementById('catalog-filter');

    if (catalogFilter) {
        let headerTouch = document.getElementById('header-touch');

        const mobileFilterOpen = document.querySelector('.catalog-filter-open-mobile');
        const mobileFilterReturns = document.querySelectorAll('.filter-return-button-mobile');
        const html = document.querySelector('html');

        mobileFilterOpen.addEventListener('click', () => {
            headerTouch.style.display = 'none';
            catalogFilter.classList.add('is-opened');
            html.classList.add('is-mobile-filter-opened');
        });

        mobileFilterReturns.forEach((mobileFilterReturn) => {
            mobileFilterReturn.addEventListener('click', () => {
                catalogFilter.classList.remove('is-opened');
                headerTouch.style.display = 'block';
                html.classList.remove('is-mobile-filter-opened');
            });
        });
    }

    setTimeout(() => {
        for (const [code, value] of Object.entries(filter)) {
            const group = document.querySelector('[data-code="' + code + '"]');

            if (group) {
                group.closest('.catalog-filter-group').classList.remove('is-closed');
            }
        }

        document.querySelectorAll('.catalog-filter-group-title').forEach((head) => {
            head.addEventListener('click', (e) => {
                const code = head.dataset.code;

                if (!filter[code]) {
                    filter[code] = true;
                } else {
                    delete filter[code];
                }

                head.closest('.catalog-filter-group').classList.toggle('is-closed');
                ls.set('filter', filter);
            });
        });
    }, 10);

    let canonicalTag = document.querySelector('link[rel="canonical"]');
    if (canonicalTag) {
        canonicalTag.setAttribute('href', window.location.href);
    }
};

catalogFilterCallback();

Fancybox.bind("[data-fancybox]", {
    Toolbar: {
        display: {
        //   left: ["infobar"],
        //   middle: [
        //     "zoomIn",
        //     "zoomOut",
        //     "toggle1to1",
        //     "rotateCCW",
        //     "rotateCW",
        //     "flipX",
        //     "flipY",
        //   ],
        //   right: ["slideshow", "thumbs", "close"],
          right: ["close"],
        },
      },
});